<template>
    <v-app>
        <HeaderMenu></HeaderMenu>
        <v-container fluid>
            <router-view></router-view>
        </v-container>
    </v-app>
</template>

<script>
import HeaderMenu from '@/components/endUser/menu/HeaderMenu'
export default {
    name: 'EndUserContainer',
    components: {
        HeaderMenu
    },
    created() {
        localStorage.setItem("isOpenAside", false);
    }
}
</script>

<style lang="scss" scoped>
.v-application {
    background-color: #f5f8fa;
    .page_bg_grey {
        background: #f5f8fa;
    }
    .page_container {
        @media (min-width: 1904px) {
            max-width: 1320px; 
        }
    }
}
</style>