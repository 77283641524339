<template>
  <div>
    <div class="header-menu white">
      <div class="d-flex align-items-center">
        <v-app-bar-nav-icon class="color-primary show-md" @click="toggleSideBar"></v-app-bar-nav-icon>
        <router-link :to="{ name: 'EndUserHome' }">
          <img src="/assets/img/logo/main-logo.png" alt="main logo" class="main-logo mx-10">
        </router-link>
        <div class="d-flex align-items-center hide-md">
          <router-link 
            v-for="(item, i) in mainItems" :key="`main-${i}`"
            :to="item.to" v-slot="{href, navigate, isExactActive }">
            <div :href="href" @click="navigate" class="menu-item" :class="[isExactActive && 'active']">
              {{ item.title }}
            </div>
          </router-link>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <HeaderSearchBar></HeaderSearchBar>
        <ChangeLangSelect></ChangeLangSelect>
        <v-menu
          bottom
          left
          offset-y
          :nudge-bottom="13"
          origin="top right"
          transition="scale-transition"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              class="ml-2"
              min-width="0"
              text
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          
          <v-list>
            <router-link to="/enduser/account">
              <v-list-item class="d-flex align-items-center">
                <v-list-item-avatar>
                  <v-img :src="s3Path + storageUser.photo" v-if="storageUser.photo"></v-img>
                  <v-img src="/assets/img/icons/face-man-outline.svg" v-else></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="text-h6">
                    {{ storageUser.first_name  + " " + storageUser.last_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ storageUser.email }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </router-link>
          </v-list>
          <v-list
            nav
            dense
            class="border-top-gray"
          >
            <v-list-item-group
              color="primary"
            >
              <v-list-item @click="handleLogout()">
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text='$t("Log off")'></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
    </div>
    <SideBar ref="sideBarRef"></SideBar>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ChangeLangSelect from '@/components/ChangeLangSelect';
import HeaderSearchBar from '@/components/endUser/menu/HeaderSearchBar';
import SideBar from './SideBar'
export default {
  name: "HeaderMenu",
  components: {
    ChangeLangSelect,
    SideBar,
    HeaderSearchBar
  },
  data() {
    return {
      s3Path: "https://bo-app.s3.amazonaws.com/public/",
      search: "",
      mainItems: [
        {
          title: this.$t("Home"),
          to: '/enduser/home',
        },
        {
          title: this.$t("Tourism"),
          to: '/enduser/tourism',
        },
        {
          title: this.$t("Mobile Prepaid"),
          to: '/enduser/communication',
        },
        {
          title: this.$t("Gift Card"),
          to: '/enduser/gift-card',
        },
        {
          title: this.$t("Games"),
          to: '/enduser/game',
        },
      ]
    }
  },
  computed: {
    ...mapState({
      loading: state => state.supplier.loading,
      products: state => state.product.products,
    }),
    storageUser() {
      const localData = localStorage.getItem("User");
      if (localData == "undefined") {
        this.logout();
        return;
      } 
        return JSON.parse(localStorage.getItem("User")) || {};
    },

  },
  methods: {
    ...mapActions('end_user', ['logout']),
    ...mapActions('product', {
      getSupplierProducts: 'getSupplierProducts',
    }),
    toggleSideBar() {
      this.$refs.sideBarRef.toggleDrawer();
    },
    async handleLogout() {
      await this.logout();
      this.$router.push('/login');
    }
  }
}
</script>

<style lang="scss" scoped>
.border-top-gray {
  border-top: 1px solid #eee;
}
.hide-md {
  @media screen and (max-width: 1263px) {
    display: none !important;
  }
}
.show-md {
  @media screen and (min-width: 1264px) {
    display: none;
  }
  @media screen and (max-width: 1263px) {
    display: block;
  }
}
.header-menu {
  height: 64px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  padding: 4px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-logo {
  width: 38px;
  object-fit: contain;
}
.menu-item {
  font-size: 14px;
  border-radius: 6px;
  padding: 6px 12px;
  margin: 0 8px;
  text-align: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: fit-content;
  &:hover {
    background-color: #eee;
    color: var(--primary-Color);
  }
  &.active {
    background-color: var(--primary-Color);
    color: #fff;
  }
}

</style>