<template>
  <v-navigation-drawer id="core-navigation-drawer" v-model="drawer"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl" mobile-break-point="960" :temporary="true" app width="260" v-bind="$attrs">
    <v-list expand nav>
      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
          <!--  -->
        </base-item-group>

          <base-item v-else :key="`item-${i}`" :item="item" />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import BaseItemGroup from './BaseItemGroup';
import BaseItem from './BaseItem';

export default {
  name: 'SideBar',
  components: {
    BaseItemGroup,
    BaseItem
  },
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      drawer: false,
      items: [
        {
          icon: 'mdi-view-dashboard',
          title: "Home",
          to: '/enduser/home',
        },
        {
          icon: 'mdi-airplane-takeoff',
          title: "Tourism",
          to: '/enduser/tourism',
        },
        {
          icon: 'mdi-cellphone-text',
          title: "Communication",
          to: '/enduser/communication',
        },
        {
          title: "Gift Card",
          icon: 'mdi-gift',
          to: '/enduser/gift-card',
        },
        {
          title: "Games",
          icon: 'mdi-gamepad-variant',
          to: '/enduser/game',
        }
      ],
    }
  },

  computed: {
    computedItems() {
      return this.items.map(this.mapItem)
    },
    profile() {
      return {
        avatar: true,
        title: this.$t('avatar'),
      }
    },
  },

  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      }
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    }
  },
}
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
