export default {
  methods: {
    handleGetSupplierRouter(type) {
      switch(type) {
        case 6:
          return "EndUserGameSupplier"
        case 5:
          return "EndUserTourism"
        case 4:
          return "EndUserGiftCardSupplier"
        case 1:
          return "EndUserCommunicationSupplier"
      }
    },
    handleGetProductRouter(type) {
      switch(type) {
        case 6:
          return "EndUserGameProduct"
        case 4:
          return "EndUserGiftCardProduct"
        case 1:
          return "EndUserCommunicationProduct"
      }
    },
  }
}