<template>
  <div>
    <div class="d-flex align-items-center mx-5 hide-md">
      <vue-select 
        label="name" 
        :filterable="false" 
        :options="options" 
        @search="onSearch" 
        :map-keydown="handlersKey" 
        class="header-search-bar"
        placeholder="Search product"
      >
        <template slot="no-options">
          type to search products..
        </template>
        <template slot="option" slot-scope="option">
          <router-link
            class="color-primary"
            :to="{ name: handleGetProductRouter(option.type), params: { id: option.id, supplier_id: option.supplier_id } }"
          >
            <div class="d-flex align-items-center">
              <img :src='option.supplier_image'/> 
              {{ option.name }}
            </div>
          </router-link>
        </template>
        <template slot="selected-option" slot-scope="option">
          <div class="selected d-flex align-items-center">
            <img :src='option.supplier_image'/> 
            {{ option.name }}
          </div>
        </template>
      </vue-select>
    </div>
    <v-menu
      bottom
      left
      offset-y
      :nudge-bottom="13"
      origin="top right"
      transition="scale-transition"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="mx-5 search_btn"
          elevation="1"
          fab
          small
          @click="handleShowSearchPanel"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item class="d-flex align-items-center">
          <vue-select 
            label="name" 
            :filterable="false" 
            :options="options" 
            @search="onSearch" 
            :map-keydown="handlersKey" 
            class="header-search-bar"
            placeholder="Search product"
          >
            <template slot="no-options">
              type to search products..
            </template>
            <template slot="option" slot-scope="option">
              <router-link
                class="color-primary"
                :to="{ name: handleGetProductRouter(option.type), params: { id: option.id, supplier_id: option.supplier_id } }"
              >
                <div class="d-flex align-items-center">
                  <img :src='option.supplier_image'/> 
                  {{ option.name }}
                </div>
              </router-link>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-flex align-items-center">
                <img :src='option.supplier_image'/> 
                {{ option.name }}
              </div>
            </template>
          </vue-select>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import endUser_mixin from '@/utility/endUser_mixin';
export default {
  name: "HeaderSearchBar",
  mixins: [endUser_mixin],
  data() {
    return {
      options: [],
      searchText: '',
      searchLoading: {}
    }
  },
  computed: {
    ...mapState({
      loading: state => state.supplier.loading,
      search_products: state => state.product.search_products,
    }),
  },
  methods: {
    ...mapActions('end_user', ['logout']),
    ...mapActions('product', {
      getSearchProducts: 'getSearchProducts',
    }),
    handlersKey(map, vm) {
      const createTag = e => {
        e.preventDefault();
        this.handleSearch();
      };
      return {
        ...map,
        13: createTag 
        // (e) => {
        //   e.preventDefault();
        //   this.handleSearch();
        //   // if (e.key === '@' && vm.search.length > 0) {
        //   //   vm.search = `${vm.search}@gmail.com`
        //   // }
        // },
      }
    },
    onSearch(search, loading) {
      this.searchText = search;
      this.searchLoading = loading;
      // if(search.length) {
      //   loading(true);
      //   this.search(loading, search, this);
      // }
    },
    handleSearch() {
      this.searchLoading(true);
      
      const params = {
        product_name: this.searchText
      };
      this.getSearchProducts({params}).then(() => {
        this.options = this.search_products;
        this.searchLoading(false);

        // loading(false);
      });
    },
    // debounce input box search
    // search(loading, search, vm) {
    //   clearTimeout(this.debounce)
    //   this.debounce = setTimeout(() => {
    //     const params = {
    //       product_name: search
    //     };
    //     this.getProducts({params}).then(() => {
    //       vm.options = this.products.filter(el => {
    //         return el;
    //       });

    //       loading(false);
    //     });
    //   }, 3000)
    // }
    handleShowSearchPanel() {

    }
  }
}
</script>

<style  lang="scss" scoped>
.header-search-bar {
  width: 250px;
  input {
    font-size: 16px !important;
  }
}

.hide-md {
  @media screen and (max-width: 1263px) {
    display: none !important;
  }
}

.search_btn {
  display: none;
  @media screen and (max-width: 1263px) {
    display: block;
  }
}

img {
  height: auto;
  max-width: 2.5rem;
  margin-right: 1rem;
}

.selected img {
  width: auto;
  max-height: 23px;
  margin-right: 0.5rem;
}

.v-select .dropdown li {
  border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
  border-bottom: none;
}

.v-select .dropdown li a {
  padding: 10px 20px;
  width: 100%;
  font-size: 1.25em;
  color: #3c3c3c;
}

.v-select .dropdown-menu .active > a {
  color: #fff;
}
</style>
